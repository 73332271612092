import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/list",
      name: "users-list",
      component: () => import("@/views/user/users-list/UsersList.vue"),
    },
    {
      path: "/users/view/:id",
      name: "users-view",
      component: () => import("@/views/user/users-view/UsersView.vue"),
    },
    {
      path: "/blaze/double/strategies",
      name: "blaze-double-strategies",
      component: () => import("@/views/blaze/double/DoubleStrategies.vue"),
    },
    {
      path: "/blaze/double/report",
      name: "blaze-double-report",
      component: () => import("@/views/blaze/double/report/DoubleReport.vue"),
    },
    {
      path: "/blaze/crash/strategies",
      name: "blaze-crash-strategies",
      component: () => import("@/views/blaze/crash/CrashStrategies.vue"),
    },
    {
      path: "/apostaganha/crash/strategies",
      name: "apostaganha-crash-strategies",
      component: () => import("@/views/apostaGanha/crash/CrashStrategies.vue"),
    },
    {
      path: "/apostaganha/crash/config",
      name: "aviator-config",
      component: () => import("@/views/apostaGanha/config/AviatorConfig.vue"),
    },
    {
      path: "/apostaganha/double/config",
      name: "eds-double-config",
      component: () => import("@/views/apostaGanha/config/DoubleConfig.vue"),
    },
    {
      path: "/bet365/futebol-virtual/strategies",
      name: "bet365-ev-strategies",
      component: () => import("@/views/bet365/futebol-virtual/Strategies.vue"),
    },
    {
      path: "/apostaganha2/aviator/strategies",
      name: "apostaganha-2-aviator-strategies",
      component: () =>
        import("@/views/apostaGanha2/aviator/AviatorStrategies.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (!store.state.auth.isLoggedIn && to.path !== "/login") {
    next({ name: "login" });
  } else next();
});

export default router;
