import router from '@/router'

const getUserData = () => JSON.parse(localStorage.getItem('userData')) || null

export default {
  namespaced: true,
  state: {
    userData: getUserData(),
    isLoggedIn: getUserData() != null,
  },
  getters: {

  },
  mutations: {
    SET_CURRENT_USER(state, user) {
      state.userData = user
      state.isLoggedIn = true
    },
  },
  actions: {
    setCurrentUser: (context, data) => {
      context.commit('SET_CURRENT_USER', data)
      localStorage.setItem('userData', JSON.stringify(data))
    },
    logout: context => {
      context.state.userData = null
      context.state.isLoggedIn = false
      localStorage.removeItem('userData')

      router.push({
        name: 'login',
      })
    },
  },
}
